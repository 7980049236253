import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'How does DataFlip access my Google Analytics data?',
    content: () => (
      <>
        DataFlip will access your Google Analytics account only when you have
        granted access. When a spot available in our Early Access program you'll
        receive an email requesting access to your Google Analytics account.
        With authentication provided, DataFlip will request Google Analytics
        data via the API.
      </>
    ),
  },
  {
    title: 'What will I receive from DataFlip?',
    content: () => (
      <>
        When your report is ready you'll receive an email in your inbox with a
        link to download the Microsoft Powerpoint presentation that you can
        easily read, edit or send on to your colleagues.
      </>
    ),
  },
  {
    title: 'What information is available in a DataFlip report?',
    content: () => (
      <>
        The DataFlip report provides summary information about your site
        performance and visitors. This includes traffic by channel, top
        referrers, active users and retention.
      </>
    ),
  },
  {
    title: 'How much does it cost?',
    content: () => (
      <>
        During the Early Access period, DataFlip provides a select number of
        users with access to reports for free.
      </>
    ),
  },
  {
    title: 'Can I connect any other data sources and receive reports?',
    content: () => (
      <>
        At this stage, we're focussing on Google Analytics data and we'll be
        working with our Early Access users to find out what data they would
        like to connect to next.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
